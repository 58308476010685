body {
  background: #0f1116;
  color: white;

  font-family: 'Lato', sans-serif;
}

.App {
  color: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  z-index: 2;
}

*:focus {
  outline: none;
}

body {
  line-height: 1.15;
  font-family: 'Lato', sans-serif;
}

h1,
h2 {
  font-family: 'Amiri', serif;

  font-weight: normal;
  text-transform: uppercase;

  padding: 0;
  margin: 0;
}

h2 {
  font-size: 2em;
}

h3 {
  font-family: 'amiri', serif;
  font-size: 1.5em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

ul,
ol {
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  color: white;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  display: inline-block;
}

img {
  max-width: 100%;
}

p {
  max-width: 700px;
  font-family: 'Lato', sans-serif;
  word-spacing: 0.5px;
  letter-spacing: 0.5px;
  font-size: 0.9em;
  margin-top: math.div($page-spacing, 2);
}

fieldset {
  border: none;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
}
