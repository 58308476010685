.wrapper {
  margin: $page-spacing;
  margin-bottom: $page-spacing*2;
  min-height: 90vh;
  position: relative;
  z-index: 1;

  &--inner {
    position: relative;
    z-index: 10;
    //max-width: 1400px;
    margin: 0 auto;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    max-height: 1.5rem;
  }

  &__bottom {
    width: 100%;
    display: inline-block;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
  }

  &__guidline {
    min-height: 1.5rem;
    width: 2px;
    background-color: rgb(255, 255, 255);
    display: flex;

    &--br {
      float: right;
      margin-bottom: 1rem;
    }

    &--bl {
      float: left;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: 900px) {
  .wrapper {
    margin-left: $page-spacing * 7;
  }
}
