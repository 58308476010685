@use "sass:math";

.form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: $page-spacing;
  max-width: 500px;

  &__label {
    margin-bottom: math.div($page-spacing, 4);
    font-size: 0.9em;
  }

  &__input {
    padding-left: math.div($page-spacing, 2);
    padding-bottom: math.div($page-spacing, 5);
    padding-top: math.div($page-spacing, 5);
    margin-bottom: $page-spacing;
    border-radius: 0;
    outline: none;
    border: 0;
    border-left: 2px solid white;
    border-right: 2px solid white;
    background-color: $background-colour;
    color: white;

    transition: 0.2s;

    &:focus,
    &--populated {
      background-color: white;
      color: black;
    }
  }

  &__button {
    max-width: 200px;
    min-width: 200px;
    min-height: 30px;
    margin: 0 auto;
    background: none;
    cursor: pointer;
    border-radius: 0;
    border: 1px solid white;
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    transition: 0.2s;
    /* display: flex;
    justify-content: center; */

    &:hover {
      transition: 0.2s;
      background-color: white;
      color: black;
    }
  }
}

textarea {
  font-family: 'Lato', sans-serif;
  font-size: 0.8em;
}
