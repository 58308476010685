@keyframes intro {
  0% {
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.intro {
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 100vw;
  // border: white solid 2px;
  top: $page-spacing * 2;
  min-height: 75%;
  background-color: $background-colour;
  z-index: 100;
  display: block;

  animation: intro 2.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &::before {
    position: relative;
    display: flex;
    vertical-align: middle;
    align-self: center;
    align-items: center;

    margin-top: 20vh;
    justify-content: center;
    content: url(../../assets/icons/logo-animated.gif);
    z-index: 101;
    transform: scale(0.8);
    transform-origin: center;
    animation: fade-in 0.25s ease;
  }

  &--finished {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .intro {
    &::before {
      margin-left: $page-spacing * 7;
    }
  }
}
