.accordion {
  margin-top: $page-spacing;
  background-color: #0f1116;
  position: relative;
  transition: 0.5s;
  width: 100%;

  &--selected {
    position: absolute;
    // top: -5px;
    background-color: #0f1116;
    transition: 0.5s;
    width: 100%;
  }

  &__segment {
    transition: 0.3s;
    position: relative;
    margin-bottom: 1rem;
    // border-left: 3px solid white;
    transition: all 0.3s;

    &::before {
      margin-top: 5px;
      transition: all 0.3s;
      content: "";
      position: absolute;
      display: inline-block;
      height: 1.5rem;
      width: 2px;
      background-color: white;
    }

    &::after {
      transition: all 0.3s;
      content: "";
      position: absolute;
      opacity: 0;
      right: 0;
      bottom: 0;
      height: 1.5rem;

      width: 2px;
      background-color: white;
    }

    &:hover {
      transition: all 0.3s;
      &::before {
        transition: all 0.3s;
        margin-left: 10px;
      }
    }
    &:focus {
      &::before {
        transition: all 0.3s;
        margin-left: 10px;
        outline: 0;
        -moz-outline: 0;
      }
    }
  }

  &__content {
    display: none;
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.3s;

    &--selected {
      animation: content-fade-in 0.3s;

      top: 0;
      display: block;

      overflow-y: scroll;
    }
  }

  &__heading {
    font-size: 1.8em;
    cursor: pointer;
    max-width: 400px;

    &--alt {
      font-size: 1.2em;
    }
  }

  &__heading,
  &__description {
    padding-left: $page-spacing;
  }

  &__description {
    margin-top: math.div($page-spacing, 4);
    margin-bottom: $page-spacing;
    color: rgb(178, 183, 190);
    font-size: 0.9em;
  }

  &__works {
    display: grid;

    overflow-x: hidden;
    :hover {
      cursor: pointer;
    }
  }

  &__work {
    padding-left: $page-spacing;
    position: relative;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 13px minmax(auto, 100%);
    grid-template-rows: auto;
    column-gap: 0px;
    row-gap: 15px;
    min-height: 150px;
    margin-bottom: $page-spacing;
    width: 100%;
    // min-height: 100%;
    // border: 2px solid white;

    &label {
      margin-right: -1px;
    }

    &title {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      text-orientation: sideways;
      font-size: 0.7em;
      padding-top: 10px;
      padding-bottom: 2px;
      position: relative;

      left: 0.5px;

      display: block;
      text-align: right;
      writing-mode: vertical-lr;
      transition: 0.3s;

      transform: rotate(180deg);
      // transform-origin: top right;
    }

    &image {
      display: block;
      object-fit: cover;
      object-position: 20% 0;
      min-height: 80%;
      border: 2px solid rgba(255, 255, 255, 0);
      transition: 0.3s;
      width: 100%;
      max-height: 200px;

      &--uncrop {
        object-fit: fill;
      }

      &--spaced {
        margin-left: 10px;
      }

      &--center {
        object-position: center;
        min-width: 150px;
      }
    }
  }

  // alt accordion

  &__experiencelist {
    // margin-top: $page-spacing/2;
    margin-left: $page-spacing * 2;
    margin-bottom: 15px;
    margin-top: 10px;
    list-style: disc;

    &item {
      font-size: 0.9em;
      //font-style: italic;
      margin-bottom: math.div($page-spacing, 4);
      //font-weight: bold;

      &--degree {
        font-weight: bold;
        margin-bottom: 4px;
      }

      &--school {
        margin-right: 0.5em;
      }

      &--years {
        color: #bbb;
      }
    }
  }

  &__skilllist {
    margin-left: $page-spacing * 0.75;
    display: flex;

    // margin-bottom: $page-spacing/2;
    //padding-top: $page-spacing/4;
    font-style: italic;

    &item {
      display: flex;
      flex-wrap: wrap;
      //margin-bottom: $page-spacing/2;
      margin-top: math.div($page-spacing, 4);
      transition: 0.2s;
    }
  }

  &__skillcontainer {
    display: inline;
    margin-right: math.div($page-spacing, 5);
    max-width: 300px;
  }

  &__skillname {
    color: rgb(164, 168, 173);
    text-transform: uppercase;
    // padding-top: $page-spacing/4;
    transition: 0.2s;
    margin-left: math.div($page-spacing, 4);
    // padding-bottom: $page-spacing/4;
  }

  &__skill {
    display: block;
    font-style: normal;

    padding: math.div($page-spacing, 4);
    //padding-left: $page-spacing/3;
    //padding-right: 0;
    margin-left: math.div($page-spacing, 4);
    margin-bottom: math.div($page-spacing, 4);
    // background-color: white;
    // color: black;
    border: 1px solid white;
    transition: 0.2s;
    // transform: skew(-10deg);
  }

  &__buttongroup {
    // margin-left: $nav-width;
    // border: 5px solid blue;
    position: fixed;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // align-self: center;
    // top: 50%;
    // width: 200px;
    // left: 25%;
    // left: 50%;
    bottom: 5%;
    z-index: 10000;
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 3em;
  }

  &__button {
    transition: 0.2s;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.11);

    min-width: 75px;
    min-height: 75px;
    font-size: inherit;
    // font-weight: bold;
    color: rgb(255, 255, 255);
    opacity: 0.8;
    margin-left: 50px;
    margin-right: 50px;
    cursor: pointer;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0);
      background-color: black;
    }

    transform: scale(0.75);
    backdrop-filter: blur(50px);
  }
}

.accordion__segment--selected {
  position: relative;
  margin-bottom: math.div($page-spacing, 2);
  transition: all 0.3s;
  // border-left: 3px solid white;
  &::before {
    transition: all 0.3s;
    content: "";
    position: absolute;
    display: inline-block;
    height: 100%;

    width: 2px;
    background-color: white;
  }

  &::after {
    transition: all 0.3s;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 1.5rem;

    width: 2px;
    background-color: white;
  }
}

.accordion__work:hover {
  .accordion__workimage {
    position: relative;
    border: 2px solid white;
    // transition: 0.3s;
  }

  .accordion__workimage--uncrop {
    border: 2px solid rgba(255, 255, 255, 0);
  }

  .accordion__worktitle {
    position: relative;
    background-color: white;
    top: 0;
    color: black;
    transition: 0.3s;
  }
}

@media only screen and (max-width: 900px) {
  .accordion {
    &--selected {
      top: -5px;
    }
    &__skill {
      //min-width: 100px;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 900px) {
  .accordion {
    &__works {
      display: grid;
      flex-wrap: wrap;
      width: 100%;

      grid-template-columns: auto auto auto;
      grid-template-rows: auto;
    }

    &__content {
      &--selected {
        scrollbar-width: none; /* Firefox */
      }
    }
  }
}
