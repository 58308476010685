$background-colour: #0f1116;
$page-spacing: 2rem;
$nav-width: 224px;

@mixin guidline {
  transition: all 0.3s;
  content: "";
  position: absolute;
  display: inline-block;
  height: 1.5rem;
  width: 3px;
  background-color: white;
}

// animations

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-slower {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-slowest {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes content-fade-in {
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 500px;
  }
}
