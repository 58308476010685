@use "sass:math";

.nav {
  // border: 1px white solid;
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
  min-height: $page-spacing * 1.5;
  background-color: $background-colour;
  display: block;
  padding-left: $page-spacing;
  padding-right: $page-spacing;
  justify-content: center;
  transition: 0.3s;
  font-family: 'Amiri', serif;
  font-size: 1.4em;
  text-transform: uppercase;
  &__list {
    display: none;
    font-family: 'Amiri', serif;
    text-transform: uppercase;
    font-size: 1.4em;
    margin-top: math.div($page-spacing, 2);

    &--open {
      display: block;
      animation: fade-in 0.3s ease;
    }

    &item {
      margin-bottom: math.div($page-spacing, 4);
      text-align: center;
    }
  }

  &__link {
    color: #9599a3;
    transition: 0.2s;

    &:hover {
      color: white;
    }
    &--active {
      color: white;
    }
  }

  &__logo {
    display: block;
    max-width: 112px;
    margin: 0 auto;
  }

  &__logo img {
    display: flex;
    justify-self: center;
    max-height: 3rem;
    padding: 0.5rem 0 0.5rem 0;
  }

  &__hamburger {
    cursor: pointer;
    position: fixed;
    right: 0;
    bottom: 0;
    vertical-align: middle;
    margin-bottom: math.div($page-spacing, 1.8);
    margin-right: $page-spacing;

    &button {
      background: none;
      outline: 0;
      border: none;
    }

    &image {
      cursor: pointer;
      animation: fade-in-slower 0.3s linear;
      &--fade {
        animation: fade-in 0.3s linear;
      }
    }
  }

  &::before {
    @include guidline;
    top: 0;
    left: 0;
    opacity: 0;
    margin-top: $page-spacing;
    margin-left: $page-spacing;
  }
  &::after {
    @include guidline;
    top: 0;
    right: 0;
    opacity: 0;
    margin-top: $page-spacing;
    margin-right: $page-spacing;
  }

  &--open {
    min-height: 50%;
    transition: 0.3s;
    padding-top: $page-spacing;
    box-shadow: 0px -7px 65px -4px rgba(0, 0, 0, 1);

    &::before {
      @include guidline;
      left: 0;
      opacity: 1;
      transition: 0.1s;
      margin-left: $page-spacing;
      height: 70%;
    }
    &::after {
      @include guidline;
      opacity: 1;
      transition: 0.1s;
      right: 0;
      margin-right: $page-spacing;
      height: 70%;
    }
  }
}

@media only screen and (min-width: 900px) {
  .nav {
    max-width: $page-spacing * 7;
    min-height: 100vh;
    font-family: 'Amiri', serif;
    font-size: 1em;
    text-transform: uppercase;

    &__list {
      display: block;
    }

    &__listitem {
      text-align: left;
      transition: 0.3s;
      & a {
        &:focus {
          transition: 0.1s;
          /*  border-left: 2px white solid;
          padding-left: 10px; */
          outline: 2px dotted rgba(105, 105, 105, 0.479);
        }
      }
    }

    &__hamburger {
      display: none;
    }

    &__logo {
      margin-top: math.div($page-spacing, 1.5);
      margin-bottom: $page-spacing * 1.5;
    }

    &__logo img {
      transform: scale(1.3);
      transform-origin: top;
    }

    &--open {
      box-shadow: none;
      &::before {
        content: '';
        display: none;
      }
      &::after {
        content: '';
        display: none;
      }
    }
  }
}
