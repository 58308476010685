@use "sass:math";

.heading {
  text-transform: uppercase;
  font-family: 'Amiri', serif;
  font-size: 2.5em;
  font-weight: normal;
  position: relative;
  margin: 0;
  top: -6px;

  &--center {
    text-align: center;
  }

  &--smaller {
    margin-top: $page-spacing;
    margin-bottom: math.div($page-spacing, 3);
  }
}
